import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Create, organize, and apply 3D models for seamless offsite product integration.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Toolbar</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Files Manager</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Uploading Files</AnchorLink>
  <AnchorLink mdxType="AnchorLink">File Version Control</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Removing Files</AnchorLink>
    </AnchorLinks>
    <p>{`The project environment is the environment you see when you are inside a project.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/57_projects-project-home.png",
      "alt": "57_projects-project-home.png"
    }}></img>
    <h2>{`Toolbar`}</h2>
    <p>{`Using the project environment toolbar on the left side of the screen, you can quickly navigate around the functionality of KOPE within your project.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/58_projects-toolbar.png",
      "alt": "58_projects-toolbar.png"
    }}></img>
    <p>{`Using the buttons, you can navigate to the following:`}</p>
    <p><img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/59_projects-toolbar-project-home.png",
        "alt": "59_projects-toolbar-project-home.png"
      }}></img>{` - Project Home`}</p>
    <p><img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/60_projects-toolbar-files-manager.png",
        "alt": "60_projects-toolbar-files-manager.png"
      }}></img>{` - Files Manager`}</p>
    <p><img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/61_projects-toolbar-configurator.png",
        "alt": "61_projects-toolbar-configurator.png"
      }}></img>{` - Configurator`}</p>
    <p><img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/62_projects-toolbar-results.png",
        "alt": "62_projects-toolbar-results.png"
      }}></img>{` - Results`}</p>
    <p><img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/63_projects-toolbar-template-manager.png",
        "alt": "63_projects-toolbar-template-manager.png"
      }}></img>{` - Template Manager`}</p>
    <h2>{`Files Manager`}</h2>
    <p>{`The Files Manager is where you upload and manage files for a project Each project has its own separate Files Manager.`}</p>
    <h3>{`Uploading Files`}</h3>
    <p>{`KOPE allows for the following file types: .bmp, .csv, .dxf, .dwg, .dwt, .glb, .ifc, .jpg, .jpeg, .json, .pdf, .png, .rvt, .svg, .xls, .xlsx. The maximum file size is 200 MB and the minimum is 100 bytes.`}</p>
    <p>{`To add files to the project Files Manager:`}</p>
    <ol>
      <li parentName="ol">{`Navigate to the project’s Files Manager`}</li>
      <li parentName="ol">{`Go to your Projects tab.`}</li>
      <li parentName="ol">{`Select the relevant project.`}</li>
      <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`Files Manager`}</inlineCode>{` button.`}</li>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Add file`}</inlineCode>{` button. The Upload New File Wizard will open.`}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/64_files-manager-add-file.png",
      "alt": "64_files-manager-add-file.png"
    }}></img>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><strong parentName="li">{`Drag and drop`}</strong>{` the file on the highlighted box or `}<strong parentName="li">{`click the box`}</strong>{` to upload your file.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/65_upload-new-file-upload-file.png",
          "alt": "65_upload-new-file-upload-file.png"
        }}></img></li>
      <li parentName="ol">{`(Optional) Enter a `}<strong parentName="li">{`Description`}</strong>{` , `}<strong parentName="li">{`Type`}</strong>{` and `}<strong parentName="li">{`Role`}</strong>{` for your file.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/66_upload-new-file-optional-attributes.png",
          "alt": "66_upload-new-file-optional-attributes.png"
        }}></img></li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{`.If your file is an IFC you willproceed to the Element Sets screen (below). Otherwise, you will process to the preview screen (see step 8).
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/67_upload-new-file-element-sets.png",
          "alt": "67_upload-new-file-element-sets.png"
        }}></img></li>
      <li parentName="ol">{`(Optional) If your file is a model, create some Element Sets to be used later when applying products to your model.`}
        <pre parentName="li"><code parentName="pre" {...{}}>{`1. Click \`New Set\`, and the New Set window will open. 
`}</code></pre>
        <img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/69_new-set-overview.png",
          "alt": "69_new-set-overview.png"
        }}></img>{`
2. Enter a `}<strong parentName="li">{`Name`}</strong>{` for your Element Set.
3. Select an `}<strong parentName="li">{`Element Category`}</strong>{` for your Element Set from the drop-down list.
4. Click `}<strong parentName="li">{`Add Condition`}</strong>{` to create some rules to filter elements into your Element Set.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/70_new-set-new-condition.png",
          "alt": "70_new-set-new-condition.png"
        }}></img>{`
5. Enter the Condition details:`}</li>
    </ol>
    <p><strong parentName="p">{`Parameter`}</strong>{` – The name of the Parameter within the IFC file.
`}<strong parentName="p">{`Filter Type`}</strong>{` – The method for filtering the elements by the Parameter and Value.
`}<strong parentName="p">{`Value`}</strong>{` – The value to filter the Parameter by.
`}<img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/68_upload-new-file-add-condition-details.png",
        "alt": "68_upload-new-file-add-condition-details.png"
      }}></img></p>
    <pre><code parentName="pre" {...{}}>{`6. Add more Conditions as needed.
7. Click \`Add Element Set\` to save the Element Set to the model.
`}</code></pre>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Once all desired Element Sets have been created. Click `}<strong parentName="li">{`Next`}</strong>{` to proceed to the preview screen.`}</li>
      <li parentName="ol">{`If satisfied with the preview, click `}<inlineCode parentName="li">{`Add File`}</inlineCode>{` to upload it to your project.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/71_upload-new-file-preview.png",
          "alt": "71_upload-new-file-preview.png"
        }}></img></li>
      <li parentName="ol">{`Your file is now being uploaded; the `}<strong parentName="li">{`Status`}</strong>{` will show as `}<strong parentName="li">{`Pending`}</strong>{` whilst the file uploads to the KOPE.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/72_files-manager-pending-upload-status.png",
          "alt": "72_files-manager-pending-upload-status.png"
        }}></img></li>
      <li parentName="ol">{`Once the upload is successfully completed, the `}<strong parentName="li">{`Status`}</strong>{` will change to `}<strong parentName="li">{`Completed`}</strong>{`.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/73_files-manager-completed-upload-status.png",
          "alt": "73_files-manager-completed-upload-status.png"
        }}></img></li>
    </ol>
    <h3>{`File Version Control`}</h3>
    <h4>{`Version Tracking`}</h4>
    <p>{`Version tracking is Automatic within KOPE. To use automatic Version tracking, the existing file on the Files Manager and the uploaded file must have the same name.`}</p>
    <h4>{`Version Control`}</h4>
    <p>{`All previously uploaded versions of a file can be viewed and downloaded from the KOPE Files Manager.`}</p>
    <p>{`To view previous versions:`}</p>
    <ol>
      <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`⫶`}</inlineCode>{` icon to the right of the file in the Files Manager.`}</li>
      <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Preview`}</inlineCode>{` from the list, and the `}<strong parentName="li">{`File Preview`}</strong>{` window will open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/74_file-version-control-previewing-files.png",
          "alt": "74_file-version-control-previewing-files.png"
        }}></img></li>
      <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`Date`}</inlineCode>{` in the top right of the window.`}</li>
      <li parentName="ol">{`Select the version you want to view from the list.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/75_file-version-control-preview-versions.png",
          "alt": "75_file-version-control-preview-versions.png"
        }}></img></li>
    </ol>
    <p>{`To download previous versions:`}</p>
    <ol>
      <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`⫶`}</inlineCode>{` icon to the right of the file in the Files Manager.`}</li>
      <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Download`}</inlineCode>{` from the list, and the `}<strong parentName="li">{`Download`}</strong>{` window will open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/76_files-manager-download-button.png",
          "alt": "76_files-manager-download-button.png"
        }}></img></li>
      <li parentName="ol">{`Select which Versions of the file to download.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/77_files-manager-download-files-select.png",
          "alt": "77_files-manager-download-files-select.png"
        }}></img></li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Download`}</inlineCode>{`, and the files will download to your device.`}</li>
    </ol>
    <h3>{`Removing Files`}</h3>
    <p>{`To remove a file:`}</p>
    <ol>
      <li parentName="ol">{`Go to the `}<inlineCode parentName="li">{`Files Manager`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`⫶`}</inlineCode>{` icon to the right of the file you want to remove.`}</li>
      <li parentName="ol">{`Select the `}<inlineCode parentName="li">{`Remove`}</inlineCode>{` option from the drop-down menu.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/78_files-manager-deleting-files.png",
          "alt": "78_files-manager-deleting-files.png"
        }}></img></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      